$(function() {
  var $window = $(window);
  var $document = $(document);
  var $header = $(".page-header");

  // Anchor smooth scroll.
  function scrollIntoView($el) {
    if (!$el || !$el.length) {
      return;
    }

    // Current scroll position.
    let top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (!$el.closest(`.carousel__viewport`).length) {
      top = $el.offset().top
      - $header.outerHeight(true) /* height of the header itself */
      - 52 /* Extra padding for visual look */;
    }

    window.scroll({
      top: top,
      behavior: "smooth"
    });

    $(`.anchor--targeted`).removeClass(`anchor--targeted`);
    $(`[href="#${$el.attr(`id`)}"]`).addClass(`anchor--targeted`);
  }

  $window.on("load hashchange", function() {
    // Had to wait for page to load so the header has dimension applied.
    var fragment = window.location.hash;
    if (!fragment) return;
    scrollIntoView($(`:target`));
  });

  $document.on("click", "a[href^=\"#\"]:not([href=\"#\"])", function(event) {
    var fragment = window.location.hash;
    var $anchor = $(this);
    var selector = $anchor.attr("href").substring(1);

    // The anchor has the same href of the hash, meaning that the user came
    // from a URL with hash, and clicking on a button that would lead to the
    // same anchor. In this case, because the hash would be the same, we
    // prevent the default jump and smooth scroll to the anchor instead.
    if (fragment !== `#maincontent` && fragment === "#" + selector) {
      event.preventDefault();
      scrollIntoView($("#" + selector));
    }
  });
});
