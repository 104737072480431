import * as smoothscroll from "../node_modules/smoothscroll-polyfill/dist/smoothscroll.js";
import * as StickyfillExports from "../node_modules/stickyfilljs/dist/stickyfill.js";
import ResizeObserver from "../node_modules/resize-observer-polyfill/dist/ResizeObserver.es.js";
import SVGInjector from '../node_modules/@tanem/svg-injector/es/svg-injector.js';
import '../node_modules/svgxuse/svgxuse.js';
import $ from "./shared/jquery.js";
import "./marathonpetroleum/polyfills.js";
import "./marathonpetroleum/tabs.js";
import "./marathonpetroleum/smoothscroll.js";
import "./marathonpetroleum/animations.js";
import "./marathonpetroleum/modal.js";
import Import from "./marathonpetroleum/import.js";
import List from "./marathonpetroleum/list.js";
import Carousel from "./marathonpetroleum/carousel.js";
import "./shared/lazy-image.js";
import StatesOfUS from "./marathonpetroleum/StatesOfUS.js";
const StatesByName = Object.keys(StatesOfUS).reduce((result, abbr) => {
  return Object.assign(result, { [StatesOfUS[abbr]]: abbr });
}, {});

// Smoothscroll polyfill would be converted to module by Rollup, so we need to call it manually here.
if (smoothscroll.polyfill) {
  smoothscroll.polyfill();
}

// Stickyfill is a CJS, but converted to module by Rollup.
var Stickyfill = window.Stickyfill;

if (!Stickyfill && StickyfillExports.default) {
  Stickyfill = StickyfillExports.default;
}

const $contributionMaps = $(`[data-type="Annual Contribution"]`);

/**
* helper function to add a class to an element
* @param {HTMLElement} el the element
* @param {string} className the class
*/
function addClass(el, className) {
	if (el.classList) {
		el.classList.add(className);
	} else if (!hasClass(el, className)) {
		el.className += ' ' + className;
	}
}

$(function () {
  const $document = $(document);
  const $window = $(`window`);
  const $body = $(`body`);
  const $header = $(`.page-header`);

  // Primary nav handlers
  $document.on(`mouseover`, `.nav--primary .isParent`, function() {
    $(this).addClass(`open`);
  });
  $document.on(`mouseout`, `.nav--primary .isParent`, function() {
    $(this).removeClass(`open`);
  });

  $document.on(`click`, `.nav--primary .isParent > button`, function() {
    const $toggle = $(this);
    const $navItem = $toggle.closest(`.isParent`);
    const $otherNavItems = $navItem.siblings();
    // Reset other items.
    $otherNavItems.removeClass(`open`);
    $otherNavItems.children(`button`).attr(`aria-expanded`, `false`);

    $navItem.toggleClass(`open`);
    if ($toggle.attr(`aria-expanded`) === `true`) {
      $toggle.attr(`aria-expanded`, `false`);
    } else {
      $toggle.attr(`aria-expanded`, `true`);
    }
  });

  /**
   * Returns a random integer between min (inclusive) and max (inclusive)
   * Using Math.round() will give you a non-uniform distribution!
   */
  function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  $(".marquee").each(function() {
    var $marquee = $(this);
    var $templates = $marquee.find("script[type=\"text/template\"]");
    var count = $templates.length;
    if (count) {
      // The marquee images has data-src instead of src (handled in XSL template).
      var randomIndex = randomInt(0, count);

      // 0 is the current marquee.
      if (randomIndex > 0) {
        var replacement = $($templates[randomIndex - 1]).html();
        $marquee.html(replacement);
      }

      var $currentImage = $marquee.find("svg[src]");
      var $noscript = $marquee.find("noscript");
      $currentImage.replaceWith($noscript.text());
      $noscript.remove();
    }
  });

  // Inject SVGs inline.
  const svgs = document.querySelectorAll(`img.svg, img[src*=".svg"]`);
  const attributesToCopy = [`src`, `width`, `height`, `class`];
  for (let i = 0, count = svgs.length; i < count; i++) {
    const svg = svgs[i];
    attributesToCopy.forEach((attrName) => {
      const attr = svg.getAttribute(attrName);

      if (attrName === `src`) {
        svg.setAttribute(attrName, ``);
      }

      if (!attr) {
        return;
      }

      svg.setAttribute(`data-${attrName}`, attr);
    });
  }
  SVGInjector(svgs, {
    each(err, svg) {
      if (err || typeof svg === `string`) {
        console.error(err || svg);
        return;
      }

      const parent = svg.parentNode;

      attributesToCopy.forEach((attrName) => {
        const attr = svg.getAttribute(`data-${attrName}`);
        svg.removeAttribute(`data-${attrName}`);

        if (!attr) {
          return;
        }

        svg.setAttribute(`${attrName}`, attr);
      });

      // Change `href` of any external SVG symbol references to be relative to the root.
      const useElements = svg.querySelectorAll(`use[href]:not([href^="#"])`);
      let useElementsCount = useElements.length;
      if (useElementsCount > 0) {
        const src = svg.getAttribute(`src`);
        const link = document.createElement(`a`);
        link.href = src;
        const directory = link.pathname.substring(0, link.pathname.lastIndexOf("/") + 1);

        while (useElementsCount--) {
          const el = useElements[useElementsCount];
          let href = el.getAttribute(`href`);
          if (!href) {
            href = el.getAttribute(`xlink:href`);
          }
          link.href = directory + href;
          let absPath = link.pathname + link.search + link.hash;
          if (absPath.indexOf(`/`) !== 0) {
            // IE10- does not have leading slash in `pathname` :/
            absPath = `/${absPath}`;
          }
          el.setAttribute(`href`, absPath);
          el.setAttribute(`xlink:href`, absPath);
        };

        // Changing the `href` with JS won't reload the SVG,
        // so we clone and replace.
        setTimeout(function() {
          const cloneElement = svg.cloneNode(true);
          parent.replaceChild(cloneElement, svg);
        });
      }

      $(svg).trigger(`injected.svg`, svg);
    },
    done(elementsLoaded) {
      $contributionMaps.trigger(`update`);
    }
  });

  // Fallback for object-fit: cover.
  if('objectFit' in document.documentElement.style === false) {
    const coverImages = document.querySelectorAll(`.marquee figure.image > img, .marquee .figure > img, .media > img, img.object-fit`);

    for (let i = 0, count = coverImages.length; i < count; i++) {
      const coverImage = coverImages[i];
      let imageUrl = coverImage.getAttribute(`data-src`);
      if (!imageUrl) {
        imageUrl = coverImage.getAttribute(`src`);
      }

      if (imageUrl) {
        const container = document.createElement(`div`);
        container.style.backgroundImage = `url(${imageUrl})`;
        addClass(container, `cover`);
        coverImage.parentNode.insertBefore(container, coverImage);
        container.appendChild(coverImage);
      }
    }
  }

  $(".sticky").each(function() {
    $(this).css("top", $header.outerHeight(true) + 15);
    Stickyfill.addOne(this);
  });

  Stickyfill.forceSticky();

  // Use JS to make adjustment to button's perspective.
  const resizeObserver = new ResizeObserver((entries, observer) => {
    entries.forEach((entry) => {
      const {target, contentRect} = entry;
      const {left: paddingLeft, width} = contentRect;

      target.style.perspective = `${((width + paddingLeft * 2) / 180).toFixed(2)}em`;
    });
  });

  const buttons = document.querySelectorAll(`.button`);
  for (let i = 0, count = buttons.length; i < count; i++) {
    resizeObserver.observe(buttons[i]);
  }

  // HTML templates
  const templates = document.querySelectorAll(`script[type="text/template"][data-href]`);

  for (let i = 0, count = templates.length; i < count; i++) {
    new Import(templates[i]);
  }

  $contributionMaps.each(function() {
    const $container = $(this),
      $form = $container.find(".filters"),
      $live = $container.find(`[aria-live]`),
      $locationFilter = $container.find(`select[name="location"]`),
      $typeFilter = $container.find(`select[name="object"]`),
      $yearFilter = $container.find(`select[name="start-time"]`);

    $locationFilter.on(`change`, function() {
      const chosen = $(this).val();
      $container.toggleClass(`contributions--table`, !!chosen);
    });

    // Update contributing states for selected contribution type and year.
    $form.on("change", function() {
      const type = $typeFilter.val();
      const year = $yearFilter.val();

      // Hash table to keep track of unique states.
      const states = {};
      // Hide all options first.
      $locationFilter
      .find(`option:not([value=""])`)
      .prop("hidden", true)
      .prop("disabled", true);

      $container.find(`[typeof="MoneyTransfer"][data-start-time="${ year }"]`)
      .filter(function() {
        return $(`[property="object"]`, this ).text().trim() === type;
      })
      .each(function() {
        const state = $(this).find(`[property="location"]`).text().trim();
        if (state && !states[state]) {
          states[state] = true;
          $locationFilter
          .find(`option[value="${ state }"]`)
          .prop("hidden", false)
          .prop("disabled", false);
        }
      });
    });

    const cache = {};

    $container.on(`update`, function(event) {
      const {results} = (event && event.data) || cache;
      cache.results = results;

      const $rows = results.filter(`tr`).removeClass(`odd`).removeClass(`even`);
      $live.find(`.results`).text($rows.length);

      // Map to state name.
      $locationFilter.find(`option`).each(function() {
        const $option = $(this);
        const locationAbbr = $option.val();
        const locationName = StatesOfUS[locationAbbr];
        if (locationName) {
          $option.text(locationName);
        }
      });

      const selectedLocation = $locationFilter.val();

      if (!selectedLocation) {
        // When on the map, we can use visible items to hide unmatched options.
        const locations = [];
        $locationFilter.find(`option:not([value=""])`)
          .prop(`hidden`, true)
          .prop(`disabled`, true);

        $container.find(`.info svg .current`).removeClass(`current`);

        $rows.each(function(index) {
          const $row = $(this);
          const selector = $row.attr(`data-location`);
          let $target = $row.find(selector);
          if (!$target.length) {
            $target = $row.find(`[property="${selector}"]`);
          }
          const location = $target.text();
          if (locations.indexOf(location) === -1) {
            locations.push(location);
          }
        });

        let locationSelector = ``;
        locations.forEach(function(name, index) {
          name = name.trim();
          if (index > 0) {
            locationSelector += `,`;
          }
          locationSelector += `.info svg [id^="${name}"]`;
          $locationFilter.find(`option[value="${name}"]`)
          .prop(`hidden`, false)
          .prop(`disabled`, false);
        });

        if (locationSelector) {
          $container.find(locationSelector).addClass(`current`);
        }
      } else {
        $rows.each(function(index) {
          const $row = $(this);
          if (index % 2) {
            $row.addClass(`odd`);
          } else {
            $row.addClass(`even`);
          }
        });
      }
    });

    $container.on(`click.contributed.location`, `.info svg [id].current`, function() {
      const stateAbbr = this.id.replace(/([A-Z]+)(?:-[\d]+)?/, `$1`)
      $locationFilter.val(stateAbbr).trigger(`change`);
    });

    $container.on(`click`, `[rel="navigate"]`, function(event) {
      event.preventDefault();
      $locationFilter.val(``).trigger(`change`);
    });
  });

  $(`.lobbying.listing`).each(function() {
    const $container = $(this);
    const $table = $container.find(`table`);
    const states = {};
    const clickStates = function (id) {
      const stateAbbr = id.replace(/([A-Z]+)(?:-[\d]+)?/, `$1`)
      const state = StatesOfUS[stateAbbr];
      const link = $table.find("a").filter(function ( index, element ) {
        if (element.text === state) {
          return element;
        }
      });
      if (link.length > 0) {
        link[0].click();
      }
    }

    $container.on(`injected.svg`, function(event) {
      const $map = $(event.data);

      $map.addClass("clickable-map");

      // The table only contains affiliated states by name.
      $table.find(`tr`).each(function() {
        const $row = $(this);
        const stateName = $row.find('td:nth-child(1)').text().trim();
        const stateAbbr = StatesByName[stateName];
        const link = $table.find("a").filter(function ( index, element ) {
          if (element.text === stateName) {
            return element;
          }
        });
        states[stateAbbr] = $row.find(`td:nth-child(2)`).html();
        // Use attribute selector because the injected elements will have their ID suffixed with number.
        $map.find(`[id^="${stateAbbr}"]`).addClass(`affiliated`);
        $map.find(`[id^="${stateAbbr}"]`).attr("tabindex","0");
        $map.find(`[id^="${stateAbbr}"]`).attr("aria-label",`${stateName} External Link ${link.attr("href")}`);
      });
    });

    $container.on(`click`, `[id].affiliated`, function(){
      clickStates(this.id);
    });
    $container.on(`keyup`, `[id].affiliated`, function(event){
      if (event.keyCode === 13) {
        clickStates(event.target.id);
      }
    });
  });

  // List
  const lists = document.querySelectorAll(`.filterable`);

  for (let i = 0, l = lists.length; i < l; ++i) {
    new List(lists[i]);
  }

  // Details
  $document.on(`click`, `details`, function(event) {
    const $details = $(this);
    if (!$details.closest(`.mce-content-body`).length) {
      $details.siblings(`details`).removeAttr(`open`);
    }
  });

  // Carousel
  const carousels = document.querySelectorAll(`.carousel`);

  for (let i = 0, l = carousels.length; i < l; ++i) {
    const carousel = carousels[i];
    const liveregion = carousel.querySelector(`.liveregion`);

    new Carousel(carousel, {
      slideSelector: `.carousel__item`,
      viewportSelector: `.carousel__viewport`,
      prevSelector: `.carousel__prev`,
      nextSelector: `.carousel__next`,
      onInit: function() {
        if (liveregion) {
          // Set liveregion text for accessibility.
          liveregion.textContent = `${this.index + 1} of ${this.slides}`;
        }
      },
      onChange: function() {
        if (liveregion) {
          // Set liveregion text for accessibility.
          liveregion.textContent = `${this.index + 1} of ${this.slides}`;
        }
      }
    });
  }

  $(`.history.carousel`).each(function() {
    const $history = $(this);
    const fragment = window.location.hash;
    const $list = $history.find(`.carousel__header .link-list`);

    // Set selected year based on fragment if any.
    if (fragment) {
      $list.find(`[href="${fragment}"]`).closest(`li`).addClass(`selected`);
    } else {
      $list.find(`.placeholder`).addClass(`selected`);
    }

    $history.on(`click`, `.carousel__header .link-list li`, function(event) {
      $list.toggleClass(`link-list--open`);
      const $target = $(event.target).closest(`li`);

      if (!$target.is(`.placeholder`)) {
        $list.find(`.selected`).removeClass(`selected`);
        $target.addClass(`selected`);
      }
    });
  });

  $document.on(`click`, `a.media[href*="youtu"], a.media[href*="vimeo.com"]`, function(event) {
    const $anchor = $(event.target);

    if ($anchor.closest(`.mce-content-body`).length) {
      return;
    }

    event.preventDefault();
    const $thumbnail = $anchor.find(`img`);
    const width = $thumbnail.attr(`width`);
    const height = $thumbnail.attr(`height`);
    const classes = $anchor.attr(`class`);
    // URL is polyfilled with polyfill.io
    const { hostname, searchParams, pathname } = new URL($anchor.attr(`href`));
    let id, src;

    if (hostname === `youtube.com` || hostname === `www.youtube.com`) {
      src = `https://www.youtube.com/embed/${searchParams.get(`v`)}?autoplay=1&rel=0`;
    } else if (hostname === `youtu.be` || hostname === `www.youtu.be`) {
      src = `https://www.youtube.com/embed${pathname.substr(0)}?autoplay=1&rel=0`;
    } else if (hostname === `vimeo.com` || hostname === `www.vimeo.com`) {
      src = `https://player.vimeo.com/video${pathname.substr(0)}?autoplay=1`;
    }

    const video = `<div class="${classes}"><iframe
      width="${width}" height="${height}" frameborder="0" allowfullscreen
      src="${src}"
      allow="accelerometer; autoplay; fullscreen; encrypted-media; gyroscope; picture-in-picture"
    ></iframe></div>`;

    $anchor.replaceWith(video);
  });

  $document.on(`click`, `#menu-toggle:checked + .page-header .nav--primary .isParent > a`, function(event) {
    const $navItem = $(this);
    const $parent = $navItem.parent();

    if (!$navItem.siblings(`ul`).length) {
      return;
    }

    event.preventDefault();

    if ($parent.hasClass(`active`)) {
      $parent.removeClass(`active`);
    } else {
      $parent.addClass(`active`).siblings().removeClass(`active`);
    }
  });

  // Close mobile menu when navigating away.
  $document.on(`click`, `#menu-toggle:checked + .page-header .nav--primary li > a`, function(event) {
    const $navItem = $(this);
    if (!$navItem.siblings(`ul`).length) {
      $("#menu-toggle").prop("checked", false);
    }
  });

  function handleFirstTab(e) {
    if (e.keyCode === 9) { // the "I am a keyboard user" key
      document.body.classList.add(`user-is-tabbing`);
      window.removeEventListener(`keydown`, handleFirstTab);
    }
  }

  window.addEventListener(`keydown`, handleFirstTab);

  $(`.map--operations`).each(function() {
    const map = this;
    const $map = $(map);
    const $places = $map.find(`.refinery, .facility`).parent();

    $map.on(`keyup`, `svg g[id][aria-expanded]`, function(event) {
      const { keyCode, target } = event;
      if (keyCode === 13) {
        $(target).trigger(`click`);
      }
    });

    $map.on(`click`, `svg`, function({ target, currentTarget }) {
      const dot = target.closest(`g[id][aria-expanded]`);
      const isOpen = dot && dot.getAttribute(`aria-expanded`) === `true`;
      // Clicking anywhere on the map will close all tooltips.
      $places.removeClass(`tooltip`);
      $body.css({
        'overflow': '',
        'padding-right': '',
      });
      $(currentTarget).find(`[role="tabpanel"][id] g[id][aria-expanded]`).attr(`aria-expanded`, `false`);

      // If it's not a expandable dot, it should be closed by the reset above.
      if (!(target.matches(`[role="tabpanel"][id] g[id][aria-expanded]`) || !dot || dot.matches(`[role="tabpanel"][id] g[id][aria-expanded]`))) {
        return;
      }

      // If it's currently open, it should be closed by the reset above.
      if (!dot || isOpen) {
        return;
      }

      const mapBoundingRect = map.getBoundingClientRect();
      const middle = mapBoundingRect.top + (mapBoundingRect.bottom - mapBoundingRect.top) / 2;
      let { top, bottom, left, right } = dot.getBoundingClientRect();
      const isAbove = (top + (bottom - top) / 2) <= (middle - 90);

      left = left + (right - left) / 2;

      if (isAbove) {
        top = bottom;
      }

      // If clicking on the dots, show tooltip for matching one.
      const controls = dot.getAttribute(`aria-controls`).split(` `);
      controls.forEach(id => {
        const place = document.getElementById(id);
        if (!place) {
          return;
        }

        const container = place.parentNode;
        const classList = container.classList;

        classList.add(`tooltip`);
        if (!isAbove) {
          classList.add(`tooltip--downward`);
        }

        container.setAttribute(`style`, `top:${ top }px;left:${ left }px`);
      });

      dot.setAttribute(`aria-expanded`, `true`);

      $body.css({
        'overflow': `hidden`,
        // Accomodate for hidden scrollbar.
        'padding-right': `${ window.innerWidth - document.documentElement.clientWidth }px`,
      });

    });
  });

  // Handles form on Martinez site
  $(`[name="attending"]`).on("change", function() {
    const $next = $(this).closest(".field-group").nextAll(".field-group");
    const usable = this.checked && this.value === "Yes";
    $next
    .toggle(usable)
    .find("input, select, textarea")
    .prop("disabled", !usable);
  })
  .closest(".field-group")
  .nextAll(".field-group")
  .hide()
  .find("input, select, textarea")
  .prop("disabled", true);
});

window.SPASCallback = function(data) {
  $(`[data-type="Post"] [typeof="ItemList"]`).each(function() {
    const $list = $(this);
    const $container = $list.closest(`[data-type="Post"]`);
    const catagories = $list.attr(`data-category`) || ``;
    let count = parseInt($list.attr(`data-count`) || 0);
    const posts = [];
    catagories.split(/,\s?/g).forEach((category) => {
      const feed = data[category];
      if (feed) {
        feed.result.forEach((post) => {
          posts.push({
            ...post,
            category: category
          });
        });
      }
    });

    // Posts are always sorted by created time.
    posts.sort((a, b) => {
      a = a.time;
      b = b.time;

      if (a > b) return -1;
      if (a < b) return 1;
      return 0;
    });

    if (!count || count > posts.length) {
      count = posts.length;
    }

    for (let index = 0; index < count; index++) {
      const { category, title, url, thumbnail, rows } = posts[index];
      const truncated = title.split(` `).splice(0, 18).join(` `)
      const ellipsis = truncated.length < title.length ? `...` : ``;

      let $item = $(`<li />`);
      if ($list.hasClass(`grid`)) {
        $item.addClass(`col-md-4`);
      }

      if (thumbnail) {
        $item.append(`
          <figure>
            <a href="${url}" class="media media--16x9">
              <img src="${thumbnail}" width="640" />
            </a>
            <figcaption>
              <a href="${url}" target="_blank">${title}</a>
            </figcaption>
          </figure>
        `);
      } else if (rows) {
        const monthNames = [`January`, `February`, `March`, `April`, `May`, `June`,
          `July`, `August`, `September`, `October`, `November`, `December`
        ];
        let [ month, year ] = title.split(/\s+/);

        monthNames.forEach((name) => {
          if (name.substring(0, 3) === month) {
            month = name;
          }
        });

        $item.attr(`data-month`, month);
        $item.attr(`data-year`, year);

        if (index === 0) {
          $item.attr(`hidden`, `hidden`);
        }

        const prices = {
          total: 0,
          count: 0
        };

        $item.append(`
          <table>
            ${ rows.reduce((acc, row, index) => {
              const tag = index === 0 ? `th` : `td`;

              if (index > 0) {
                const price = parseFloat(row[row.length - 1]);
                prices.total += price;
                prices.count ++;
              }

              return `${ acc }
              <tr>${
                row.reduce((acc, cell) => `${ acc }
                <${ tag }>${ cell }</${ tag }>
              `, ``)
              }</tr>`;
            }, ``) }
          </table>
        `);

        $item.attr(`data-average`, (prices.total/prices.count).toFixed(4));

      } else {
        $item.append(`
          <a href="${ url }" target="_blank">
            <span class="icon-${category}"></span>
            ${ truncated }${ ellipsis }
          </a>
        `)
      }

      $list.append($item);
    }

    $container.on(`update`, function(event) {
      const { results } = (event && event.data);
      $container.find(`.bulletins__month`).html(results.attr(`data-month`));
      $container.find(`.bulletins__year`).html(results.attr(`data-year`));
      $container.find(`.bulletins__average`).html(results.attr(`data-average`));

      const year = results.attr(`data-year`);
      const $monthFilter = $container.find(`.filters [name="month"]`);

      if (year) {
        const $siblings = results.parent().find(`[data-year="${ year}"]`);

        $monthFilter.find(`option:not([value=""]):not([value="*"])`).attr(`hidden`, `hidden`);
        $siblings.each(function() {
          const month = $(this).attr(`data-month`);
          $monthFilter.find(`option[value="${ month }"]`).removeAttr(`hidden`);
        });
      } else {
        $monthFilter.val(new Date().toLocaleString("default", { month: "long" }));
        $monthFilter.trigger("change");
      }

    });
  });

  $(`.link-list--calendar`).each(function() {
    const $container = $(this);
    const category = $container.attr(`data-category`);
    let count = parseInt($container.attr(`data-count`) || 0);
    let events = data.calendar.result[category] || [];

    // Removes expired events
    const now = Date.now();
    events = events.filter(({"Start Date": StartDate, "Start Time": StartTime}) => {
      return now <= new Date(`${StartDate} ${StartTime}`);
    }).sort((a, b) => {
      a = new Date(`${a["Start Date"]} ${a["Start Time"]}`);
      b = new Date(`${b["Start Date"]} ${b["Start Time"]}`);

      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });

    if (!count || count > events.length) {
      count = events.length;
    }

    let locationCount = 0;
    $container.prepend(`<meta property="numberOfItems" content="${locationCount}" />`);

    for (let index = 0; index < count; index++) {
      let {
        Location,
        Subject,
        "Start Date": StartDate,
        "End Date": EndDate,
        "Start Time": StartTime,
        "End Time": EndTime,
        Where,
        "Pre-Register": RegisterURL,
        ...rest
      } = events[index];

      if (!EndDate) {
        EndDate = StartDate;
      }

      let $item = $container.find(`li[data-location="${Location}"]`);

      if (!$item.length) {
        $container.children(`meta[property="numberOfItems"]`).attr(`content`, ++locationCount);

        $item = $(`<li property="itemListElement" typeof="ListItem ItemList" data-location="${Location}" hidden="hidden"></li>`);
        $item.append(`<h3>Events at ${Location}</h3>`);
        $item.append(`<meta property="numberOfItems" content="0" />`)
        $container.append($item);
      }

      const $numberOfItems = $item.children(`meta[property="numberOfItems"]`);
      const numberOfItems = parseInt($numberOfItems.attr(`content`)) + 1;
      $numberOfItems.attr(`content`, numberOfItems);

      const $dl = $(`<dl property="itemListElement" typeof="Event" />`);
      $dl.append(`<meta property="position" content="${numberOfItems}" />`);

      let currentDate = new Date(`${StartDate} ${StartTime}`);
      let endDate = new Date(`${EndDate} ${EndTime}`);
      let time = StartTime;
      if (EndTime) {
        time += ` - ${EndTime}`;
      }

      // Split the event duration into multiple ones per day.
      while (currentDate <= endDate) {
        const datetime = [
          currentDate.getMonth() + 1,
          currentDate.getDate(),
          currentDate.getFullYear()
        ];

        $dl.append(`
          <div>
            <dt>Date</dt>
            <dd>
              <time datetime="${currentDate.toISOString()}" property="startDate">
                ${datetime.join(`/`)}
                <span hidden="hidden">${StartTime}</span>
              </time>
            </dd>
          </div>
        `);

        $dl.append(`
          <div>
            <dt>Event</dt>
            <dd property="about">${Subject}</dd>
          </div>
        `);

        $dl.append(`
          <div>
            <dt>Time</dt>
            <dd>${time}</dd>
          </div>
        `);

        $dl.append(`
          <div>
            <dt>Location</dt>
            <dd property="location">${Where}</dd>
          </div>
        `);

        Object.keys(rest).forEach((key) => {
          const value = rest[key];
          $dl.append(`
            <div>
              <dt>${key}</dt>
              <dd>${value}</dd>
            </div>
          `);
        });

        if (RegisterURL) {
          $dl.append(`
          <div>
            <dt class="a11y-sr-only">Register URL</dt>
            <dd><a class="secondary" href="${ RegisterURL }" target="_blank">Pre-Register</a></dd>
          </div>
          `);
        }

        // Advance to next day if any.
        currentDate.setDate(currentDate.getDate() + 1);
      }

      $item.append($dl);
    }
  });
}
