import dialogPolyfill from "../../node_modules/dialog-polyfill/dist/dialog-polyfill.esm.js";

$(function() {
  const $document = $(document);
  const $body = $(`body`);

  const noop = function() {};

  // Can't use `showModal` in SAM due to z-index when editing.
  const action = document.getElementById(`SAMAdminTools`)? `show` : `showModal`;

  // Lightbox
  $document.on(`click`, `a[rel*="lightbox"]`, function(event) {
    const $button = $(this);
    const target = $button.attr(`href`);
    let $dialog, predefined = false;

    const $editor = $button.closest(`.mce-content-body`);
    if ($editor.length) {
      return;
    }

    // @TODO: Check if target is anchor or URL;
    if (target.indexOf(`#`) === 0) {
      // Open an anchor
      const $target = $(target);
      $dialog = $target.closest(`dialog`);
      predefined = true;
    } else {
      event.preventDefault();
      // Open a URL.
      $dialog = $button.siblings(`dialog`);
      if (!$dialog.length) {
        $dialog = $(`<dialog class="modal" />`);
        let $content;

        if (/\.(jpeg|jpg|gif|png|svg)$/.test(target)) {
          $content = $(`<div class="container">
            <a href="#close">
              <span class="icon-close" aria-hidden="true"></span>
              <span class="a11y-sr-only">Close</span>
            </a>
            <img class="dialog__image" src="${ target }" />
          </div>`);
          showModal($content);
        } else if(/youtu/.test(target)) {
          const { hostname, searchParams, pathname } = new URL(target);
          let id;
          if (hostname === `youtube.com` || hostname === `www.youtube.com`) {
            id = searchParams.get(`v`);
          } else if (hostname === `youtu.be` || hostname === `www.youtu.be`) {
            id = pathname.substr(0);
          }
          $content = $(`<div class="container">
            <a href="#close">
              <span class="icon-close" aria-hidden="true"></span>
              <span class="a11y-sr-only">Close</span>
            </a>
            <div class="media media--16x9">
              <iframe
                width="900" height="450" frameborder="0"  allowfullscreen
                src="https://www.youtube.com/embed/${id}?autoplay=1&controls=0&rel=0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>
          </div>`);
          showModal($content);
        } else {
          $content = $(`
            <div class="container article__container">
              <a href="#close">
                <span class="icon-close" aria-hidden="true"></span>
                <span class="a11y-sr-only">Close</span>
              </a>
              <div class="article-content overflow-auto">
                <p>Loading...</p>
              </div>
            </div>`);
          showModal($content);
          fetch(target)
            .then(response => response.text())
            .then(data => {
              let $article = $(data);
              let $header = $article.find("article header");
              let $body = $article.find("[property='articleBody']");
              let $dialogContent = $dialog.find(".article-content")
              $body.removeClass("content");
              $dialogContent.empty().append($header).append($body);
              $dialogContent.append("<a class=\"button\" href=\"#close\">Close Window</a>");
            });
        }

        function showModal($content) {
          $button.after($dialog);
          $dialog.append($content);
          dialogPolyfill.registerDialog($dialog[0]);
        }

      }
    }

    if ($dialog && $dialog.length) {
      $dialog[0][action]();

      if (predefined) {
        return;
      }

      $dialog.on(`close`, function() {
        $dialog.remove();
      });
    }
  });

  $(`dialog`).each(function() {
    dialogPolyfill.registerDialog(this);

    // When user presses "Esc".
    $(this).on(`cancel`, function() {
      const target = document.querySelector(`:target`);

      // If the dialog was open through anchor link, clicking close should go back.
      if (target) {
        window.history.go(-1);
      }
    })
  });

  $document
  .on(`close`, `dialog`, noop)
  .on(`click`, `dialog`, function(event) {
    const dialog = event.target;
    const container = dialog.querySelector(`.container, .modal__container`);
    if (!container) {
      // Clicked inside the container.
      return;
    }

    const rect = container.getBoundingClientRect();
    const isInDialog=(rect.top <= event.clientY && event.clientY <= rect.top + rect.height
      && rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
    if (!isInDialog) {
      dialog.close();
    }
  })
  .on(`click`, `dialog [href="#close"]`, function(event) {
    const target = document.querySelector(`:target`);
    $(event.target).closest(`dialog`)[0].close();

    // If the dialog was open through anchor link, clicking close should go back.
    if (target) {
      window.history.go(-1);
    }
    return false;
  });

  [`load`, `hashchange`].forEach((type) => {
    window.addEventListener(type, () => {
      $(`dialog`).each(function() {
        if (this.open) {
          this.close();
        }
      });
      const target = document.querySelector(`:target`);
      if (!target) return;

      const $target = $(target);
      const $dialog = $target.closest(`dialog`);

      if ($dialog.length) {
        $dialog[0][action]();
      }
    });
  });
});
